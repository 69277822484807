/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
	Box,
	Typography,
	Grid,
	Autocomplete,
	TextField,
	Button,
	CircularProgress,
	Checkbox,
	Stack,
} from '@mui/material';
import {
	Add as AddIcon,
	ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import * as xlsx from 'xlsx';
import { useSnackbar } from 'notistack';
import {
	firebaseKeys,
	statesKeys,
	inverseStatesKeys,
	initialFiltersStations,
	selectedPreviousDeal,
	checkedPreviousDeal,
	initialFilterStationAtom,
	filterDataList,
	filterLoading,
	filterSelectedOwnerList,
	filterTypeList,
	customStaGroupIdsArr,
	isNewButtonDisabled,
	dealCallsArr,
	previousDealsDataArray,
	filterAqhList,
	specialCities,
} from '../utils/constants';
import {
	combineSales,
	capitalizeStations,
	handleDealWithLetters,
} from '../utils/utils';
import {
	collection,
	onSnapshot,
	query,
	limit,
	where,
	getDocs,
	FieldPath,
	documentId,
	deleteDoc,
} from 'firebase/firestore';
import { db } from '../../../firebase/config';
import {
	getUniqueArr,
	SF_Client_A_F,
	sortWithGroup,
	splitArrayIntoChunks,
} from '../../../utils';
import { useAtom } from 'jotai';
import SaveAsAqhDialog from '../components/SaveAsAqhDialog';
import useAuthContext from '../../../hooks/useAuthContext';
import AreYouSure from '../../../components/AreYouSure';
import UpdateStaGroup from '../components/UpdateStaGroup';
import { chunk } from 'lodash';

const _ = require('lodash');
const Typesense = require('typesense');
let client = new Typesense.Client({
	nodes: [
		{
			host: '7514stdpxwmboy9fp-1.a1.typesense.net',
			port: '443',
			protocol: 'https',
		},
	],
	apiKey: 'scuwRIFdrsmHAgaLDpuGQKK5AIiM2ZuC',
	connectionTimeoutSeconds: 10,
});

const FilterBox = styled(Box)(({ theme }) => ({
	marginBottom: '15px',
	'.MuiTypography-root': {},
	'.action_btn': {
		gap: '5px',
		'& button': {
			minWidth: '130px',
			marginRight: '5px',
			borderRadius: theme.spacing(1),
			padding: '2px 5px',
			'&:hover': {
				'&.add': {
					backgroundColor: '#0081ea',
				},
				'&.save': {
					backgroundColor: '#9E9E9E',
				},
			},
		},
	},
}));

const FilterForm = ({
	setStationList,
	ownerList,
	cityList,
	DMAList,
	dialList,
	formatList,
	GMNameList,
	selectedRows,
	setSelectedRows,
	staGroups,
	sales,
	setCustomStaGroupData,
	importCsv,
	setImportCsv,
	refDb,
	refreshKey,
	olderFilters,
	updateStations,
}) => {
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const hiddenFileInput = React.useRef(null);
	const [dataList, setDataList] = useAtom(filterDataList);
	const [loading, setLoading] = useAtom(filterLoading);
	const [filters, setFilters] = useAtom(initialFilterStationAtom);
	const [selectedOwnersList, setSelectedOwnersList] = useAtom(
		filterSelectedOwnerList
	);
	const [onTypeList, setOnTypeList] = useAtom(filterTypeList);
	const [customStaGroupIds, setCustomStaGroupIds] =
		useAtom(customStaGroupIdsArr);
	const [dealCalls, setDealCalls] = useAtom(dealCallsArr);
	const [open, setOpen] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [disabled, setDisabled] = useAtom(isNewButtonDisabled);
	const [previousDealsData, setPreviousDealsData] = useAtom(
		previousDealsDataArray
	);
	const { user } = useAuthContext();
	const [aqhList, setAqhList] = useAtom(filterAqhList);
	const [ownerOptions, setOwnerOptions] = useState([]);
	const [cityOptions, setCityOptions] = useState([]);
	const [formatOptions, setFormatOptions] = useState([]);
	const [nameOptions, setNameOptions] = useState([]);
	const [dmaOptions, setDmaOptions] = useState([]);
	const [dialOptions, setDialOptions] = useState([]);
	const [checkedPrevious, setCheckedPrevious] = useAtom(checkedPreviousDeal);
	const [selectedPrevious, setSelectedPrevious] = useAtom(selectedPreviousDeal);
	// each time that selected rows are updated this array update too, fory the parent usseEffect
	// save selected stations documents Ids to group it after when user use "Save as" button
	const selectedSatationsIds = selectedRows.map((row) => row?.id);
	const typeSenseCollection = client.collections('stationsUpdated').documents(); // updated collection

	const seasonsRef = collection(db, 'seasons');
	const [currentRef, setCurrentRef] = useState(null);
	const [showSave, setShowSave] = useState(false);
	const [customStaGroupDataId, setCustomStaGroupDataId] = useState(null);
	const [openUpdateStaGroup, setOpenUpdateStaGroup] = useState(false);
	//add older filters
	useEffect(() => {
		if (olderFilters) {
			setFilters(olderFilters);
			if (updateStations) {
				handleAddStation();
			}
		}
	}, [updateStations]);
	// get a current and past seasons collection
	useEffect(() => {
		const getSeasons = async () => {
			const q = query(seasonsRef, limit(1));
			const seasonsDocs = await getDocs(q);
			let seasonsResults = [];
			seasonsDocs.forEach((doc) => {
				seasonsResults.push({ ...doc.data(), seasonId: doc.id });
			});
			setCurrentRef(seasonsResults[0]?.currentSeason);
		};
		getSeasons();
	}, []);
	const handleOptionsOwner = (e) => {
		let searchParameters = {
			q: e.target.value,
			query_by: 'Ownername',
			per_page: 250, // Increased results per page
		};

		typeSenseCollection
			.search(searchParameters)
			.then(function (searchResults) {
				let owners = searchResults.hits.map((hit) => hit.document.Ownername);
				let uniqueOwners = [...new Set(owners.sort())];
				setOwnerOptions(uniqueOwners);
			})
			.catch((error) => {
				console.log('error owner filter', error);
			});
	};
	// Transform each word in LicCity from the first to uppercase
	const handleCityUpperCase = (str) => {
		const words = str.split(' ');

		// Capitalize the first letter of each word
		const capitalizedWords = words.map((word) => {
			// Make sure the word is not empty
			if (word.length === 0) {
				return word;
			}
			// Capitalize the first letter and keep the rest lowercase
			return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
		});

		// Join the words back into a string
		return capitalizedWords.join(' ');
	};

	const handleOptionsCity = (e) => {
		let searchParameters = {
			q: e.target.value,
			query_by: 'Mcity,LicCity',
			per_page: 250,
		};

		typeSenseCollection
			.search(searchParameters)
			.then(function (searchResults) {
				// get the unique cities
				const uniqueCities = [];
				// handle the cities, Mcity and LicCity
				searchResults.hits.forEach(({ document }) => {
					// if the city is in the special cities array, then it should be in uppercase
					// otherwise, should be in the format of the handleCityUpperCase function
					const city = specialCities.includes(document.Mcity)
						? document.Mcity.toUpperCase()
						: handleCityUpperCase(document.Mcity);
					// if the city is not in the unique cities array, then add it
					if (!uniqueCities.includes(city)) {
						uniqueCities.push(city);
					}
					// if the city is in the special cities array and is not in the unique cities array, then add it
					if (
						specialCities.includes(document.LicCity) &&
						!uniqueCities.includes(document.LicCity)
					) {
						uniqueCities.push(document.LicCity);
					}
				});
				// sort the unique cities array
				uniqueCities.sort();
				// set the city options
				setCityOptions(uniqueCities);
			})
			.catch((error) => {
				console.log('error city filter', error);
			});
	};

	const handleOptionsFormat = (e) => {
		let searchParameters = {
			q: e.target.value,
			query_by: 'FmtName1',
			per_page: 250,
		};

		typeSenseCollection
			.search(searchParameters)
			.then(function (searchResults) {
				let formats = searchResults.hits.map((hit) => hit.document.FmtName1);
				let uniqueFormats = [...new Set(formats.sort())];
				setFormatOptions(uniqueFormats);
			})
			.catch((error) => {
				console.log('error formats filter', error);
			});
	};
	const handleOptionsName = (e) => {
		let searchParameters = {
			q: e.target.value,
			query_by: 'GM2,GM3',
			per_page: 250,
		};
		typeSenseCollection
			.search(searchParameters)
			.then(function (searchResults) {
				let names = searchResults.hits.map(
					(hit) => hit.document.GM2 + ' ' + hit.document.GM3
				);
				let uniqueNames = [...new Set(names.sort())];
				setNameOptions(uniqueNames);
			})
			.catch((error) => {
				console.log('error names filter', error);
			});
	};
	const handleOptionsDMA = (e) => {
		let searchParameters = {
			q: e.target.value,
			query_by: 'NDMAName',
			per_page: 250,
		};
		typeSenseCollection
			.search(searchParameters)
			.then(function (searchResults) {
				let dmas = searchResults.hits.map((hit) => hit.document.NDMAName);
				let uniqueDmas = [...new Set(dmas.sort())];
				setDmaOptions(uniqueDmas);
			})
			.catch((error) => {
				console.log('error DMA filter', error);
			});
	};
	const handleOptionsDial = (e) => {
		let searchParameters = {
			q: e.target.value,
			query_by: 'Freq',
			per_page: 250,
		};
		typeSenseCollection
			.search(searchParameters)
			.then(function (searchResults) {
				let dials = searchResults.hits.map((hit) => hit.document.Freq);
				let uniqueDials = [...new Set(dials.sort())];
				setDialOptions(uniqueDials);
			})
			.catch((error) => {
				console.log('error Dial filter', error);
			});
	};

	const handleChange = async (name, value) => {
		// handle Custom Sta Group Field Change
		// and get all groups with that selected name from loaded groups
		if (name === 'previousDeal') {
			setDealCalls([]);
			setSelectedPrevious(value);
			setCheckedPrevious(true);
			await handleDealScheduleCall(value);
		} else if (name === 'customStaGroup') {
			if (staGroups?.length > 0) {
				const filtered = staGroups.filter((group) => group?.name === value);
				if (filtered?.length > 0) {
					filtered.forEach((groupValue) => {
						if (groupValue?.documentsIds?.length > 0) {
							const data = { name: groupValue.name, id: groupValue.id };
							setCustomStaGroupDataId(data || null);
							setCustomStaGroupIds(groupValue.documentsIds);
						}
					});
				}
			}
			return setFilters({ ...filters, customStaGroup: value });
		} else if (name === 'call') {
			return setFilters({
				...filters,
				[name]: value ? value[firebaseKeys[name]] : null,
			});
		}
		setFilters({ ...filters, [name]: value });
	};

	const handleTypo = (e) => {
		const name = e.target.id;
		const text = name === 'call' ? e.target.value?.toUpperCase() : e.target.value;
		if (!text) return false;
		const end = text.replace(/.$/, (c) =>
			String.fromCharCode(c.charCodeAt(0) + 1)
		);
		const q = query(
			refDb,
			where(firebaseKeys[name], '>=', text),
			where(firebaseKeys[name], '<', end),
			limit(8)
		);
		const unsub = onSnapshot(q, (snapshot) => {
			let results = [];
			snapshot.docs.forEach((doc) => {
				results.push({ ...doc.data(), id: doc.id });
			});

			const uniqueIds = [];

			const unique = results.filter((element) => {
				const isDuplicate = uniqueIds.includes(element[firebaseKeys[name]]);

				if (!isDuplicate) {
					uniqueIds.push(element[firebaseKeys[name]]);

					return true;
				}

				return false;
			});
			setOnTypeList({ ...onTypeList, [name]: unique });
		});
		return unsub;
	};
	// const hanldeClear = () => setFilters(initialFiltersStations)
	// Create a item key
	const getItemKey = (item) => {
		switch (item[1]) {
			case 'FM':
				return 'A' + item[0];
			case 'AM':
				return 'B' + item[0];
			case 'F2':
				return 'C' + item[0];
			case 'F3':
				return 'D' + item[0];
			case 'FM4':
				return 'E' + item[0];
			default:
				return null;
		}
	};

	function orderByKey(a, b) {
		const ka = getItemKey(a.frequency),
			kb = getItemKey(b.frequency);
		if (ka > kb) {
			return 1;
		} else if (ka < kb) {
			return -1;
		}
		return 0;
	}

	const orderStations = (stations) => {
		if (stations) {
			// The frequency of the station is extracted
			const allStations = stations.map((station) => {
				const stationWithFreq = {
					...station,
					frequency: station.Station.split('-', 2),
				};
				return stationWithFreq;
			});

			// Stations ordered according to freq like FM,AM,HD2
			const sortAllStations = allStations.sort(orderByKey);

			// Stations ordered by city
			return _.orderBy(sortAllStations, [
				function (o) {
					return o.Mcity;
				},
			]);
		}
		return [];
	};

	useEffect(() => {
		const refreshKeyValue = refreshKey;
		if (refreshKeyValue) {
			handleAddStation();
		}
	}, [refreshKey]);

	// get the custom Sta Groups documents and set the data on the table
	const getCustomStagroupDocuments = async (notNullValue) => {
		setFilters({
			...initialFiltersStations,
			customStaGroup: notNullValue.customStaGroup,
		});
		let documentRef = collection(db, '08242022');
		try {
			if (customStaGroupIds?.length === 0) return setStationList([]);
			const documentQuery = query(
				documentRef,
				where(documentId(), 'in', customStaGroupIds)
			);
			const documentsSnapshot = await getDocs(documentQuery);
			if (documentsSnapshot?.docs?.length > 0) {
				let result = [];
				documentsSnapshot.docs.forEach((doc) => {
					result.push({ ...doc.data(), id: doc.id });
				});
				const unique = getUniqueArr(result, ['id']);
				setDataList(unique);
			}
		} catch (error) {
			console.log('error here', error);
		}
	};

	// get the custom Sta Groups documents and set the data on the table
	const getAllStationsDocuments = async (notNullValue) => {
		let documentRef = collection(db, '08242022');
		const chunckDealCalls = chunk([...new Set(dealCalls)], 10);

		let result = [];
		const data = await Promise.all(
			chunckDealCalls.map(async (chunckCalls) => {
				const documentQuery = query(
					documentRef,
					where('Station', 'in', chunckCalls)
				);
				const querySnapshot = await getDocs(documentQuery);
				querySnapshot.forEach((doc) => {
					setSelectedOwnersList([...selectedOwnersList, doc.id]);
					result.push({ ...doc.data(), id: doc.id });
				});
				return result;
			})
		);
		let mergedResultsOrdered = orderStations(data[0]);
		// only run when loop reaches to last index
		let sortedArray =
			selectedRows.length > 0
				? mergedResultsOrdered.sort((a, b) => {
						let isSelected = selectedRows.find(
							(row) => row.Station === a.Station && row.FmtName1 === a.FmtName1
						);
						if (isSelected) return -1;
						else return 0;
				  })
				: mergedResultsOrdered;

		const sortedResult = await filterArray(sortedArray, notNullValue);
		const finalResult = [...selectedRows, ...sortedResult];
		const unique = getUniqueArr(finalResult, ['Station']);
		if (unique.length === 0) return setStationList([]);
		setDataList(unique);
	};

	// Detect if a result is in uppercase
	const isUpperCase = (str) => {
		return str === str.toUpperCase();
	};
	//function filter array
	const filterArray = async (data, filter) => {
		if (Object.keys(filter).length <= 0) return data;
		const dataTemp = data.reduce(
			(current, object) => {
				if (filter?.state) {
					const keys = inverseStatesKeys[filter.state];
					if (object.Mstate !== keys) return current;
				}
				if (filter?.call) {
					if (object.Station !== filter.call) return current;
				}

				if (filter?.owner) {
					if (object.Ownername !== filter.owner) return current;
				}
				if (filter?.market) {
					if (object.NDMAName !== filter.market) return current;
				}

				if (filter?.dial) {
					if (object.Freq !== filter.dial) return current;
				}
				if (filter?.city) {
					if (isUpperCase(filter?.city) && object.LicCity !== filter.city)
						return current;
					else if (object.Mcity !== filter.city) return current;
				}

				if (filter?.format) {
					if (!object.FmtName1.includes(filter.format)) return current;
				}
				if (filter?.name) {
					const nameSplit = filter.name.split(' ');
					if (object?.GM2) {
						if (object.GM2 !== nameSplit[0]) return current;
					}
					if (object?.GM3) {
						if (object.GM3 !== nameSplit[1]) return current;
					}
				}
				current.push(object);
				return current;
			},
			[],
			[]
		);
		return dataTemp;
	};
	// get the other filter methods documents and set the data on the table
	const getOtherfilteredDocuments = async (notNullValue, hasFilter) => {
		const queryParams = [
			// limit(1500)
		];
		let filterQuerry = [];
		if (notNullValue?.state) {
			let state = notNullValue.state;
			// inverse the state to get the correct state name to search on firebase
			notNullValue.state = inverseStatesKeys[notNullValue.state];
			// if only state filter exist then search on firebase with state
			filterQuerry.push(where('Mstate', '==', notNullValue.state));
			delete notNullValue.state;
		}
		if (notNullValue?.call) {
			filterQuerry.push(where('Station', '==', notNullValue.call));
			delete notNullValue.call;
		}
		if (notNullValue?.name) {
			filterQuerry.push(
				where('GM2', '==', notNullValue.name.split(' ')[0]),
				where('GM3', '==', notNullValue.name.split(' ')[1])
			);
			delete notNullValue.name;
		}
		if (notNullValue?.city && notNullValue?.city !== undefined) {
			// if city is in uppercase then search on firebase with LicCity
			const cityNameField = isUpperCase(notNullValue.city) ? 'LicCity' : 'Mcity';
			// add the filters to the query
			filterQuerry.push(where(cityNameField, '==', notNullValue.city));
			delete notNullValue.city;
		}
		if (notNullValue?.owner) {
			// add the filters to the query
			filterQuerry.push(where('Ownername', '==', notNullValue.owner));
			delete notNullValue.owner;
		}
		if (Object.keys(notNullValue).length > 0) {
			// Validates if there are extra parameters for the query
			const filterQuerryOutParamas = Object.keys(notNullValue).map((v) =>
				where(firebaseKeys[v], '==', notNullValue[v])
			);
			filterQuerry.push(...filterQuerryOutParamas);
		}

		queryParams.push(...filterQuerry);
		let mergedResults = [];
		let splitClientQueryArr = splitArrayIntoChunks(10, SF_Client_A_F);
		splitClientQueryArr.map((queryArr, idx) => {
			let queries = [
				...queryParams,
				limit(5000),
				where(new FieldPath('Client_SunFun.A_F'), 'in', queryArr),
			];
			const q = query(refDb, ...queries);
			const unsub = onSnapshot(q, (snapshot) => {
				let results = [];
				snapshot.docs.forEach((doc) => {
					setSelectedOwnersList([...selectedOwnersList, doc.id]);
					results.push({ ...doc.data(), id: doc.id });
				});
				mergedResults = [...mergedResults, ...results];
				let mergedResultsOrdered = orderStations(mergedResults);
				// only run when loop reaches to last index
				if (splitClientQueryArr.length - 1 <= idx) {
					let sortedArray =
						selectedRows.length > 0
							? mergedResultsOrdered.sort((a, b) => {
									let isSelected = selectedRows.find(
										(row) => row.Station === a.Station && row.FmtName1 === a.FmtName1
									);
									if (isSelected) return -1;
									else return 0;
							  })
							: mergedResultsOrdered;
					const finalResult = [...selectedRows, ...sortedArray];
					const unique = getUniqueArr(finalResult, ['id']);
					// if user use Previous Deal filter this if filter it locally with saved Stations Name.
					if (hasFilter === true && unique.length !== 0) {
						const filtered = unique.filter((station) => {
							return dealCalls.some((call) => {
								return station.Station === call;
							});
						});
						if (filtered.length === 0) return setStationList([]);
						setDataList(filtered);
					} else {
						// if user dont use previous deal filter dont filter the data list and load normally.
						if (unique.length === 0) return setStationList([]);
						setDataList(unique);
					}
				}
			});
			return unsub;
		});
	};

	const handleAddStation = async () => {
		// if (selectedOwnersList.includes()) return false
		let notNullValue = {};
		for (let prop in filters) {
			if (filters[prop] !== null && filters[prop] !== undefined) {
				notNullValue = { ...notNullValue, [prop]: filters[prop] };
			}
		}
		if (notNullValue && Object.values(notNullValue).length === 0) return false;

		Object.values(notNullValue).length > 0 && setLoading(true);

		if (notNullValue?.customStaGroup) {
			getCustomStagroupDocuments(notNullValue);
		} else if (notNullValue?.previousDeal) {
			getAllStationsDocuments(notNullValue);
		} else {
			getOtherfilteredDocuments(notNullValue, false);
		}
		localStorage.setItem('filters', JSON.stringify(filters));
		if (customStaGroupIds?.length > 0) {
			setShowSave(true); // show the save button
		}
	};

	const handleConfirmation = async () => {
		setFilters(initialFiltersStations);
		setStationList([]);
		setSelectedRows([]);
		setDataList([]);
		setAqhList([]);
		setShowSave(false); // hide the save button
		setCustomStaGroupDataId(null);
		setCheckedPrevious(false);
		setSelectedPrevious(null);
		localStorage.removeItem('filters');
		localStorage.removeItem('selectRows');
		localStorage.removeItem('aqhCalculator');
		const documentsRef = collection(db, 'aqhSavedOptions');
		// get current user saved options documents to delete it.
		const q = query(documentsRef, where('userId', '==', user.uid));
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach(async (doc) => {
			try {
				// delete user saved options.
				await deleteDoc(doc.ref);
				setDisabled(true);
			} catch {
				console.log(`error deleting saved option document: ${doc.id}`);
			}
		});

		setOpenDialog(false);
	};

	useEffect(() => {
		if (dataList.length > 0 && loading) {
			if (currentRef) {
				//current ratings
				const currentRatingRef = collection(db, currentRef);
				const scheduleRef = collection(db, 'schedule');
				// past ratings
				const pastBookRef = collection(db, 'spr-2024'); // Latest numbers 03/2025
				// all past ratings
				const pastSprinRef = collection(db, 'spr-2021');
				const refSpr = collection(db, 'spr-2022');
				const pastSprinRef2023 = collection(db, 'spr-2023');
				const pastRef = collection(db, 'fall-2021');
				const pastFallRef = collection(db, 'fall-2020');
				const pastFall2022Ref = collection(db, 'fall-2022');
				const pastFall2023Ref = collection(db, 'fall-2023');

				let allStations = dataList.map((s) => s.Station);

				let spliSalesIntoSmallArr = splitArrayIntoChunks(10, allStations);

				let scheduleArr = [];
				let batchArrSpr2022 = [];
				let batchArrSpr2023 = [];
				let batchArrFall2021 = [];
				let batchArrSpr2021 = [];
				let batchArrFall2020 = [];
				let batchArrFall2022 = [];
				let batchArrFall2023 = [];
				let batchArrSpr2024 = [];
				let currentResults = [];

				spliSalesIntoSmallArr.map(async (queryArr, idx) => {
					const pastQ = query(pastRef, where('Station', 'in', queryArr));
					const pastFallQ = query(pastFallRef, where('Station', 'in', queryArr));
					const pastFall2022Q = query(
						pastFall2022Ref,
						where('Station', 'in', queryArr)
					);
					const pastFall2023Q = query(
						pastFall2023Ref,
						where('Station', 'in', queryArr)
					);
					const pastSpr2024Q = query(pastBookRef, where('Station', 'in', queryArr)); // spring-2024 (past book ref)

					let resultsPast = [];
					let resultsPastFall = [];
					let resultsPastFall2022 = [];
					let resultsPastFall2023 = [];
					// Past Ratings
					const pastQuerySnapshot = await getDocs(pastQ);
					const pastFallQuerySnapshot = await getDocs(pastFallQ);
					const pastFall2022QuerySnapshot = await getDocs(pastFall2022Q);
					const pastFall2023QuerySnapshot = await getDocs(pastFall2023Q);
					// Past Fall 2020
					pastFallQuerySnapshot.docs.forEach((doc) => {
						resultsPastFall.push({ ...doc.data(), id: doc.id, fall2020: doc.data() });
					});
					batchArrFall2020 = [...batchArrFall2020, ...resultsPastFall];
					// Past Fall 2021
					pastQuerySnapshot.docs.forEach((doc) => {
						resultsPast.push({ ...doc.data(), id: doc.id, fall2021: doc.data() });
					});
					batchArrFall2021 = [...batchArrFall2021, ...resultsPast];

					// Past Fall 2022
					pastFall2022QuerySnapshot.docs.forEach((doc) => {
						resultsPastFall2022.push({
							...doc.data(),
							id: doc.id,
							fall2022: doc.data(),
						});
					});
					batchArrFall2022 = [...batchArrFall2022, ...resultsPastFall2022];

					// Past Fall 2023
					pastFall2023QuerySnapshot.forEach((doc) => {
						resultsPastFall2023.push({
							...doc.data(),
							id: doc.id,
							fall2023: doc.data(),
						});
					});
					batchArrFall2023 = [...batchArrFall2023, ...resultsPastFall2023];

					// Spring 2024 (past book)
					const pastSpr2024QuerySnapshot = await getDocs(pastSpr2024Q);
					let resultsPastSpr2024 = [];
					pastSpr2024QuerySnapshot.forEach((doc) => {
						resultsPastSpr2024.push({
							...doc.data(),
							id: doc.id,
							spring2024: doc.data(),
						});
					});
					batchArrSpr2024 = [...batchArrSpr2024, ...resultsPastSpr2024];

					// Spring data
					let result = [];
					let resultsSpr2021 = [];
					let resultsSpr2023 = [];
					// Past Springs
					const querySpr = query(refSpr, where('Station', 'in', queryArr));
					const queryPastSpr = query(pastSprinRef, where('Station', 'in', queryArr));
					const querySpr2023 = query(
						pastSprinRef2023,
						where('Station', 'in', queryArr)
					);

					const querySnapshot = await getDocs(querySpr); // spring 2021
					const queryPastSnapshot = await getDocs(queryPastSpr); // spring 2022
					const queryPastSprSnapshot = await getDocs(querySpr2023); // spring 2023
					//Spring 2021
					queryPastSnapshot.forEach((doc) => {
						resultsSpr2021.push({
							...doc.data(),
							id: doc.id,
							spring2021: doc.data(),
						});
					});
					batchArrSpr2021 = [...batchArrSpr2021, ...resultsSpr2021];

					//Spring 2022
					querySnapshot.forEach((doc) => {
						result.push({ ...doc.data(), id: doc.id, spring2022: doc.data() });
					});
					batchArrSpr2022 = [...batchArrSpr2022, ...result];

					// Spring 2023
					queryPastSprSnapshot.forEach((doc) => {
						resultsSpr2023.push({
							...doc.data(),
							id: doc.id,
							spring2023: doc.data(),
						});
					});
					batchArrSpr2023 = [...batchArrSpr2023, ...resultsSpr2023];

					// Current Ratings
					let currentResultRatings = [];
					const queryCurrent = query(
						currentRatingRef,
						where('Station', 'in', queryArr)
					);
					const queryCurrentSnapshot = await getDocs(queryCurrent);
					//Spring 2024
					queryCurrentSnapshot.forEach((doc) => {
						currentResultRatings.push({
							Station: doc.data().Station,
							Aqh: doc.data()['MF 6a-7p'],
							dayParts: doc.data(),
						});
					});
					currentResults = [...currentResults, ...currentResultRatings];

					let ratingsArr = currentResults.map((data) => {
						// past book - spring 2024
						let getAqhSpr2024 = batchArrSpr2024?.find(
							(sprObj) => sprObj['Station'] === data['Station']
						);
						// fall 2023
						let getAqhFall2023 = batchArrFall2023?.find(
							(Obj) => Obj['Station'] === data['Station']
						);
						//spring 2023
						let getAqhSpr2023 = batchArrSpr2023?.find(
							(Obj) => Obj['Station'] === data['Station']
						);
						//spring 2022
						let getAqhSpr2022 = batchArrSpr2022?.find(
							(Obj) => Obj['Station'] === data['Station']
						);
						//fall 2022
						let getAqhFall2022 = batchArrFall2022?.find(
							(Obj) => Obj['Station'] === data['Station']
						);
						//fall 2021
						let getAqhFall2021 = batchArrFall2021?.find(
							(Obj) => Obj['Station'] === data['Station']
						);
						return {
							...data,
							fall2024: data?.Aqh,
							pastBook: getAqhSpr2024,
							spring2024: getAqhSpr2024?.spring2024 ? getAqhSpr2024?.spring2024 : 0,
							fall2023: getAqhFall2023?.fall2023 ? getAqhFall2023?.fall2023 : 0,
							spring2023: getAqhSpr2023?.spring2023 ? getAqhSpr2023?.spring2023 : 0,
							fall2022: getAqhFall2022?.fall2022 ? getAqhFall2022?.fall2022 : 0,
							spring2022: getAqhSpr2022?.spring2022 ? getAqhSpr2022?.spring2022 : 0,
							fall2021: getAqhFall2021?.fall2021 ? getAqhFall2021?.fall2021 : 0,
						};
					});

					let ratingsArrPast = batchArrFall2020.map((data) => {
						let getAqh = batchArrSpr2021?.find(
							(sprObj) => sprObj['Station'] === data['Station']
						);
						return {
							...data,
							spring2021: getAqh?.spring2021 ? getAqh?.spring2021 : 0,
							// pastBook: getAqh,
							fall2020: data?.fall2020 ? data?.fall2020 : 0,
						};
					});

					let ratingsAllArr = ratingsArr.map((data) => {
						let getAqh = ratingsArrPast?.find(
							(sprObj) => sprObj['Station'] === data['Station']
						);
						return {
							...data,
							spring2021: getAqh?.spring2021,
							fall2020: getAqh?.fall2020,
							fall2021: data?.fall2021,
							spring2022: data?.spring2022,
							fall2022: data?.fall2022,
							spring2023: data?.spring2023,
							fall2023: data?.fall2023,
							spring2024: data?.spring2024,
							fall2024: data?.fall2024,
						};
					});

					let scheduleResults = [];

					// Schedule Data
					const scheduleQ = query(scheduleRef, where('Calls', 'in', queryArr));
					const scheduleQuerySnapshot = await getDocs(scheduleQ);
					scheduleQuerySnapshot.forEach((doc) => {
						scheduleResults.push({ ...doc.data(), id: doc.id });
					});
					scheduleArr = [...scheduleArr, ...scheduleResults];

					if (spliSalesIntoSmallArr.length === idx + 1) {
						let mergeData = dataList.map((data) => {
							let getAqh = ratingsAllArr?.find(
								(sprObj) => sprObj['Station'] === data['Station']
							);
							// sorted the schedule data by end date
							let sortedSchedule = scheduleArr.sort((a, b) => {
								return new Date(b['End date']) - new Date(a['End date']);
							});
							// find the deal number on the schedule data
							let getSchedule = sortedSchedule?.find((sprObj) => {
								// if user is filtering previous deal find the deal number on the schedule data
								if (filters?.previousDeal) {
									return sprObj['Deal'] === filters?.previousDeal;
								} else {
									// if user is another filter find the deal number on the schedule data
									return sprObj['Calls'] === data['Station'];
								}
							});
							// filter the deal number on the sales data
							const salesFilter = sales.filter(
								(sprObj) => sprObj['Deal #'] === getSchedule?.Deal
							);
							// combine the sales data
							const getSales = combineSales(salesFilter);

							return {
								...data,
								Aqh: getAqh ? getAqh.Aqh : 0,
								dayParts: getAqh?.dayParts,
								pastBook: getAqh?.pastBook,
								spring2021: getAqh?.spring2021,
								fall2020: getAqh?.fall2020,
								fall2021: getAqh?.fall2021,
								spring2022: getAqh?.spring2022,
								fall2022: getAqh?.fall2022,
								spring2023: getAqh?.spring2023,
								fall2023: getAqh?.fall2023,
								spring2024: getAqh?.spring2024,
								fall2024: getAqh?.fall2024,
								deal: getSchedule?.Deal ? getSchedule?.Deal : '',
								dealDetails: getSales
									? { ...getSales, endDateSchedule: getSchedule?.['End date'] ?? '' }
									: 'No Sale',
							};
						});
						// remove duplicates
						mergeData = mergeData.reduce((unique, item) => {
							const isDuplicate = unique.some(
								(existingItem) => existingItem.Station === item.Station
							);
							if (!isDuplicate) {
								unique.push(item);
							}
							return unique;
						}, []);
						setStationList(sortWithGroup(mergeData, 'Mcity', 'Station'));
						setAqhList(sortWithGroup(mergeData, 'Mcity', 'Station'));
						setDataList([]);
						setLoading(false);
					}
				});
			}
		}
	}, [dataList, setStationList, loading, currentRef]);

	useEffect(() => {
		if (loading) {
			setTimeout(() => {
				setLoading(false);
			}, 5000);
		}
	}, [loading]);

	// send to calculator the selected rows
	const hanldeAHQCalc = () => {
		navigate('/dashboard/aqhcalculator', {
			state: {
				data: {
					selectedRowsAqh: selectedRows,
					dataList: aqhList,
					previousDealsData: previousDealsData,
					dealTerms: checkedPrevious === true ? selectedPrevious : null,
					useLocalStorage: true,
				},
			},
		});
	};
	// if the previous deal was selected, it is kept and sent to the calculator as deal terms
	const handleCheckedPrevious = (e) => {
		setCheckedPrevious(e.target.checked);
	};

	//if the previous deal was selected, it is kept and sent to the calculator as deal terms
	useEffect(() => {
		if (filters.previousDeal !== null && checkedPrevious === true) {
			setSelectedPrevious(filters.previousDeal);
		}
	}, [filters.previousDeal, checkedPrevious, setSelectedPrevious]);

	useEffect(() => {
		// disable or enable new button after filters value changes.
		const getValidationFilter = () => {
			return (
				filters.call ||
				filters.city ||
				filters.customStaGroup ||
				filters.dial ||
				filters.format ||
				filters.market ||
				filters.name ||
				filters.owner ||
				filters.researchData ||
				filters.state ||
				filters.previousDeal
			);
		};
		// disable or enable new button if user has saved options.
		const getSavedOptions = async () => {
			const documentsRef = collection(db, 'aqhSavedOptions');
			// get current user saved options documents to delete it.
			const q = query(documentsRef, where('userId', '==', user.uid));
			const querySnapshot = await getDocs(q);
			if (querySnapshot?.docs?.length > 0) {
				setDisabled(false);
			} else {
				if (getValidationFilter()) {
					setDisabled(false);
				} else {
					setDisabled(true);
				}
			}
		};
		getSavedOptions();
	}, [filters]);

	useEffect(() => {
		const staFilterization = (getStationKeys) => {
			const q = query(refDb, where('Station', 'in', getStationKeys));

			const unsub = onSnapshot(q, (snapshot) => {
				let results = [];
				snapshot.docs.forEach((doc) => results.push({ ...doc.data(), id: doc.id }));
				setCustomStaGroupData(results);
			});
			return unsub;
		};
		if (filters.customStaGroup && staGroups?.length > 0) {
			setImportCsv(null);
			let getStationKeys = [];
			let getStation = staGroups.find(
				(group) => group.name === filters.customStaGroup
			);
			getStation = Object.entries(getStation).map(
				([key]) => key !== 'name' && key !== 'id' && getStationKeys.push(key)
			);
			staFilterization(getStationKeys);
		}
		if (importCsv && importCsv.length > 0) {
			let getStationKeys = importCsv.map((v) => v.Station.toUpperCase());
			staFilterization(getStationKeys);
		}
	}, [
		filters.customStaGroup,
		importCsv,
		setCustomStaGroupData,
		setImportCsv,
		staGroups,
	]);

	const handleCsvChange = (e) => {
		e.preventDefault();
		setFilters((prev) => ({ ...prev, customStaGroup: null }));
		if (e.target.files) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const data = e.target.result;
				const workbook = xlsx.read(data, { type: 'array' });
				const sheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[sheetName];
				const json = xlsx.utils.sheet_to_json(worksheet);

				if (json) {
					let filteredData = json.filter((c) => 'Station' in c);
					filteredData.length === 0
						? enqueueSnackbar('no data found in Csv!', { variant: 'error' })
						: setImportCsv(filteredData);
				} else {
					console.log('no data found in Csv!');
				}
			};
			reader.readAsArrayBuffer(e.target.files[0]);
		} else {
			console.log('no data found in Csv!');
		}
	};
	const handleClick = (event) => {
		hiddenFileInput.current.click();
	};

	// filter sales to dont show empty zero or N/A on Deal filter.
	const filteredPreviousSales = sales.filter((sale) => {
		const filtered =
			sale['Deal #'] !== '' && sale['Deal #'] !== '0' && sale['Deal #'] !== 'N/A';
		return filtered;
	});

	// remove duplicated and sort Previous Deal # to show it on Deal Filter.
	const uniqueSales = [
		...new Set(filteredPreviousSales.map((item) => item['Deal #']).sort()),
	];

	// get schedule Deal from Deal Filter
	const handleDealScheduleCall = async (deal) => {
		if (deal && deal != null) {
			const scheduleRef = collection(db, 'schedule');
			const scheduleQuery = query(scheduleRef, where('Deal', '==', deal));
			const result = [];
			const dealData = [];
			const unsub = onSnapshot(scheduleQuery, (snapshot) => {
				snapshot.docs.forEach((doc) => {
					const data = {
						station: doc.data()?.Calls || null,
						dayPart: doc.data()?.Daypart || null,
						noOfSpots: doc.data()?.['MPW by daypart'] || null,
						weeks: doc.data()?.Weeks || null,
					};
					dealData.push(data);
					result.push(doc.data().Calls);
				});

				// handle to transform stations in uppercase
				// If the station names are lowercase, they are converted to uppercase
				const handleStations = capitalizeStations(dealData);

				// If the Daypart is the same or different and the weeks are different, the highest value of weeks is taken
				const groupedData = {}; // Object to store grouped data
				// Iterate through each element in handleStations
				handleStations.forEach((item) => {
					// Create a unique key combining station and dayPart
					const key = item.station + '-' + item.dayPart;
					// Check if the key doesn't exist yet or if the number of weeks is greater than the existing one for that key
					if (!groupedData[key] || +groupedData[key].weeks < +item.weeks) {
						// Assign the current object to that key in groupedData
						groupedData[key] = item;
					}
				});

				// Get the unique items with the most weeks for each season and day combination
				const unique = Object.values(groupedData);
				
				// Find the maximum weeks for each station
				const maxWeeksByStation = {};
				unique.forEach(item => {
					if (!maxWeeksByStation[item.station] || parseInt(item.weeks) > parseInt(maxWeeksByStation[item.station])) {
						maxWeeksByStation[item.station] = item.weeks;
					}
				});

				// Filter out entries that have less weeks than the maximum for their station
				const uniqueDealData = unique.filter(item => 
					parseInt(item.weeks) === parseInt(maxWeeksByStation[item.station])
				);
				// capitalize the deal calls
				const capitalizeDealCalls = result.map((call) => call.toUpperCase());
				setPreviousDealsData(uniqueDealData);
				setDealCalls(capitalizeDealCalls);
			});
			return unsub;
		}
	};

	// Options to disable button to the calculator
	const handleDisable = () => {
		if (loading) {
			return true;
		}
		// if user has selected previous deal, checked deal terms and has selected rows enabled button
		if (
			filters.previousDeal !== null &&
			checkedPrevious === true &&
			selectedRows.length > 0
		) {
			return false;
		} else if (
			filters.previousDeal !== null &&
			checkedPrevious === false &&
			selectedRows.length > 0
		) {
			// if user has selected previous deal, not checked deal terms and has selected rows disable button
			return true;
		} else if (!filters.previousDeal && selectedRows.length > 0) {
			// if user has not selected previous deal and has selected rows enabled button
			return false;
		} else if (selectedRows.length === 0) {
			// if user has not selected rows disable button
			return true;
		}
	};

	return (
		<FilterBox>
			<Typography variant="h6" mb={1}>
				Filter By
			</Typography>
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<Autocomplete
						options={ownerOptions || []}
						getOptionLabel={(option) => option}
						isOptionEqualToValue={(option, value) => option === value}
						id="owner"
						size="small"
						value={filters.owner || null}
						onChange={(_, newValue) => handleChange('owner', newValue)}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Owner"
								variant="standard"
								onChange={handleOptionsOwner}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={4}>
					<Autocomplete
						options={cityOptions || []}
						getOptionLabel={(option) => option}
						isOptionEqualToValue={(option, value) => option === value}
						id="city"
						size="small"
						value={filters.city || null}
						onChange={(_, newValue) => handleChange('city', newValue)}
						renderInput={(params) => (
							<TextField
								{...params}
								label="City"
								variant="standard"
								onChange={handleOptionsCity}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={4}>
					<Autocomplete
						options={formatOptions || ['']}
						getOptionLabel={(option) => option}
						isOptionEqualToValue={(option, value) => option === value}
						id="format"
						size="small"
						value={filters.format}
						onChange={(_, newValue) => handleChange('format', newValue)}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Format"
								variant="standard"
								onChange={handleOptionsFormat}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={4}>
					<Autocomplete
						options={nameOptions || ['']}
						getOptionLabel={(option) => option}
						isOptionEqualToValue={(option, value) => option === value}
						id="name"
						size="small"
						value={filters.name}
						onChange={(_, newValue) => handleChange('name', newValue)}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Name"
								variant="standard"
								onChange={handleOptionsName}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={4}>
					<Autocomplete
						options={dmaOptions || []}
						getOptionLabel={(option) => option}
						isOptionEqualToValue={(option, value) => option === value}
						id="market"
						size="small"
						value={filters.market || null}
						onChange={(_, newValue) => handleChange('market', newValue)}
						renderInput={(params) => (
							<TextField
								{...params}
								label="DMA"
								variant="standard"
								onChange={handleOptionsDMA}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={4}>
					<Autocomplete
						options={Object.values(statesKeys) || []}
						getOptionLabel={(option) => option}
						isOptionEqualToValue={(option, value) => option === value}
						id="state"
						size="small"
						value={filters.state}
						onChange={(_, newValue) => handleChange('state', newValue)}
						renderInput={(params) => (
							<TextField {...params} label="State" variant="standard" />
						)}
					/>
				</Grid>
				<Grid item xs={4}>
					<Autocomplete
						options={dialOptions || []}
						getOptionLabel={(option) => option}
						isOptionEqualToValue={(option, value) => option === value}
						id="disable-close-on-select"
						size="small"
						value={filters.dial}
						onChange={(_, newValue) => handleChange('dial', newValue)}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Dial"
								variant="standard"
								onChange={handleOptionsDial}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={4}>
					<Autocomplete
						options={onTypeList.call || []}
						getOptionLabel={(option) => {
							if (option.hasOwnProperty('Station')) {
								return option['Station'];
							}
							return option || ' ';
						}}
						isOptionEqualToValue={(option, value) => option['Station'] === value}
						id="call"
						size="small"
						value={filters.call}
						onChange={(_, newValue) => handleChange('call', newValue)}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Call"
								variant="standard"
								onChange={handleTypo}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={4}>
					<Stack
						direction="row"
						alignItems="center"
						spacing={1}
						className="custom_sta_group"
					>
						<Autocomplete
							options={staGroups.map((group) => group.name) || []}
							getOptionLabel={(option) => option}
							isOptionEqualToValue={(option, value) => option === value}
							id="customStaGroup"
							size="small"
							fullWidth
							value={filters.customStaGroup}
							onChange={(_, newValue) => handleChange('customStaGroup', newValue)}
							renderInput={(params) => (
								<TextField {...params} label="Custom Sta Group" variant="standard" />
							)}
						/>
						{showSave && customStaGroupDataId ? (
							<Button
								className="save"
								size="small"
								variant="contained"
								sx={{ backgroundColor: '#9E9E9E' }}
								onClick={() => {
									setOpenUpdateStaGroup(true);
								}}
							>
								Save
							</Button>
						) : (
							''
						)}
					</Stack>
				</Grid>
				<Grid item xs={4}>
					<Autocomplete
						options={uniqueSales.map((sale) => sale) || []}
						getOptionLabel={(option) => option}
						isOptionEqualToValue={(option, value) => option === value}
						id="previousDeal"
						size="small"
						value={filters.previousDeal}
						onChange={(_, newValue) => handleChange('previousDeal', newValue)}
						renderInput={(params) => (
							<TextField {...params} label="Previous Deal" variant="standard" />
						)}
					/>
				</Grid>

				{selectedPrevious && (
					<Grid item xs={8}>
						<Stack direction="row" alignItems="center" spacing={1}>
							<Checkbox checked={checkedPrevious} onChange={handleCheckedPrevious} />
							<Typography>use as most Recent Deal Terms {selectedPrevious}</Typography>
						</Stack>
					</Grid>
				)}

				<Grid item container xs={12} spacing={2}>
					<Grid item xs={12} className="action_btn">
						<Button
							className="add"
							size="small"
							variant="contained"
							onClick={(_) => {
								handleAddStation();
							}}
							sx={{ backgroundColor: '#42A5F5' }}
							endIcon={<AddIcon />}
							disabled={loading}
							startIcon={loading && <CircularProgress size={12} />}
						>
							Add stations
						</Button>
						<Button
							className="upload"
							size="small"
							variant="contained"
							color="info"
							onClick={handleClick}
						>
							Upload CSV
						</Button>
						<input
							type="file"
							name="upload"
							id="upload"
							ref={hiddenFileInput}
							onChange={handleCsvChange}
							style={{ display: 'none' }}
						/>
						<Button
							size="small"
							variant="contained"
							color="success"
							endIcon={<ArrowForwardIcon />}
							onClick={hanldeAHQCalc}
							disabled={handleDisable()}
						>
							AQH Calc
						</Button>
						<Button
							className="new"
							size="small"
							variant="contained"
							sx={{ backgroundColor: '#42A5F5' }}
							onClick={() => setOpenDialog(true)}
							disabled={disabled}
						>
							New
						</Button>
						<AreYouSure
							open={openDialog}
							setOpen={setOpenDialog}
							handleYes={handleConfirmation}
							onClose={() => {
								setOpenDialog(false);
							}}
						/>
						<UpdateStaGroup
							openStaGroup={openUpdateStaGroup}
							setOpenStaGroup={setOpenUpdateStaGroup}
							customStaGroupDataId={customStaGroupDataId}
							setCustomStaGroupDataId={setCustomStaGroupDataId}
							selectStationIds={selectedSatationsIds}
						/>
						<SaveAsAqhDialog
							open={open}
							userId={user.uid}
							setOpen={setOpen}
							selectedDocumentsIds={selectedSatationsIds}
						/>
					</Grid>
				</Grid>
			</Grid>
		</FilterBox>
	);
};

export default FilterForm;
